import React from 'react';
import { compose } from 'recompose';
import { Link } from 'gatsby'

import Layout from '../components/layout';
import {
  withAuthorization,
} from '../components/Session';
import * as ROLES from '../constants/roles';
import Navigation from '../components/Navigation';
import SignUpForm from '../components/SignUp';

const AddUsersPageBase = () => (
  <div>
    <Navigation />

    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold leading-tight text-gray-900">
          Add Users
        </h1>
        <p>
          The Add Users Page is accessible to every signed in admin user.
        </p>
      </div>
    </header>

    <main>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className='sm:w-full w-11/12 mx-auto'>
          <div className='sm:w-full w-1/2 mt-12'>
            <h2 className='text-lg mb-2 font-bold'>Add New User</h2>
            <p className='mb-6'>NOTE: Please save the password you created and share it to the new user.</p>

            <SignUpForm />

            <Link to='/admin'>
              <button
                type="button"
                className='group relative w-full flex justify-center py-2 px-4 my-6 border border-red-900 text-sm leading-5 font-medium rounded-md text-red-900 hover:text-white bg-white hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mb-3'
              >
                Back to Admin Dashboard
              </button>
            </Link>
          </div>
        </div>
      </div>
    </main>
  </div>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const AddUsersPage = compose(
  withAuthorization(condition),
)(AddUsersPageBase);

export default () => (
  <Layout>
    <AddUsersPage />
  </Layout>
);
